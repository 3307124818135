/* ESTILOS PARA PAGE RELATORIOS MAIN PAGE */

header.relatorios-main {
    display: flex;
    justify-content: center;
    position: relative;
}

header.relatorios-main div.input-group {
    width: 100%;
}

header.relatorios-main span.input-group-text {
    cursor: pointer;
}

section.relatorios-main {
    padding: 1rem 0px;
}

section.relatorios-main div.nav-container {
    display: flex;
    flex-direction: column;
}

section.relatorios-main div.nav-item {
    display: flex;
    flex-direction: row;
    padding: 1rem 0.5rem;
    color: #6265ea;
}

section.relatorios-main svg {
    margin: auto 0px;
    cursor: pointer;
}

section.relatorios-main a.nav-link {
    display: inline;
    color: #6265ea !important;
}

div.nenhum-relatorio {
    padding: 1rem 0px;
}

.hover-popup {
    position: absolute;
    top: 50px;
    left: 0;
    transform: translateX(13%);
    padding: 5px 10px;
    background-color: #e9e9e9;
    color: #212529;
    border-radius: 5px;
    border: 1px solid #dee2e6;
    white-space: wrap;
    z-index: 10 !important;
    transition: 1s ease-in-out;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    width: 50%;
}

.hover-popup > {
    margin: 5px;
}

/* screens md */
@media (min-width: 768px) {
    header.relatorios-main div.input-group {
        width: 50%;
    }
    
    section.relatorios-main div.nav-container {
        flex-direction: row;
    }
}
